import { Link, useNavigate } from "react-router-dom";
import HeadTags from "shared/HeadTags";
import { useEffect } from 'react';

import img_sale0824 from 'assets/images/sale_022024.jpeg';
import img_sale from 'assets/images/sale_060824.png';
import img_card from 'assets/images/diskont15.12.jpg';
import img_kraska from 'assets/images/slidla_kraska.jpg';
import img_emal from 'assets/images/slidla_emal.jpg';
import img_provoda from 'assets/images/slidki_provoda.jpg';
import img_lustry from 'assets/images/slidla_lustry.jpg';
import img_pvhpaneli from 'assets/images/skidki_pvhpaneli.jpg';
import ym from 'react-yandex-metrika';

const PromoPage = () => {
	const navigate = useNavigate();

	useEffect(() => {
		ym('hit', '/akczii');
		window.scrollTo({
			top: 0,
			behavior: "smooth"
		});
	}, [navigate]);

	return (
		<div className="container mr-tp-20">
			<HeadTags
				title='Акции и распродажа стройматериалов в Орле - магазин "Универсал"'
				metaDescription='Скидки и распродажа стройматериалов в Орле. Сезонные и специальные  предложения от поставщиков.'
			/>
			<ul className="breadcrumbs" itemScope itemType="http://schema.org/BreadcrumbList">
				<li className="breadcrumb-item" itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
					<Link to="/" itemProp="item">Главная</Link>
					<meta itemProp="position" content="1" />
				</li>
				<li className="breadcrumb-item active" itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
					<span itemProp="item">Акции</span>
					<meta itemProp="position" content="2" />
				</li>
			</ul>
			<h1 className="mr-tp-20">Акции</h1>
			<p>&nbsp;</p>
			<div className="sale_item">
				<div className="sale_item__img">
					<img alt="Скидка 5% по промокоду Универсал 57" className="sale-img" src={img_sale0824} title="Скидка 5% по промокоду Универсал 57" />
				</div>
				<div className="sale_item__text">
					<p className="sale-capt"><span>Скидка в августе -15 %</span></p>
					<div className="sale-desc">
						<p style={{ maxWidth: '580px' }}><small>-Грунт-эмали по ржавчине 3 в 1 АЗ<br/> -Грунт-эмали по ржавчине 3 в 1 быстросохнущая ОПТИЛЮКС<br/> -Эмаль ПФ-115 Стандарт ОПТИЛЮКС<br/> -Эмаль для пола ПФ-266 Стандарт ОПТИЛЮКС</small></p>
					</div>
				</div>
			</div>
			<div className="sale_item">
				<div className="sale_item__img">
					<img alt="Скидка 5% по промокоду Универсал 57" className="sale-img" src={img_sale} title="Скидка 5% по промокоду Универсал 57" />
				</div>
				<div className="sale_item__text">
					<p className="sale-capt"><span>Скидки по промокоду УНИВЕРСАЛ_57</span></p>
					<div className="sale-desc">
						<p style={{ maxWidth: '580px' }}><small>-5% на постельное бельё<br/></small></p>
					</div>
				</div>
			</div>
			<div className="sale_item">
				<div className="sale_item__img">
					<img alt="Программа лояльности! Скидка 5% по дисконтной карте. - фото" className="sale-img" src={img_card} title="Программа лояльности! Скидка 5% по дисконтной карте." />
				</div>
				<div className="sale_item__text">
					<p className="sale-capt"><span>Программа лояльности! Скидка <b>5%</b> по дисконтной карте.</span></p>
					<div className="sale-desc">
						<p>Выдается бесплатно при покупке от 5000 руб.</p>
					</div>
				</div>
			</div>
			<div className="sale_item">
				<div className="sale_item__img">
					<img alt="Скидка 10% на краску" className="sale-img" src={img_kraska} title="Скидка 10% на краску ПФ-115 Ярославский колорит, Норма, Хозяин, Красотка" />
				</div>
				<div className="sale_item__text">
					<p className="sale-capt"><span>Скидка</span> <b>до 30%</b> на краску</p>
					<div className="sale-desc">
						<p>ПФ-115 Ярославский колорит, Норма, Хозяин, Красотка</p>
					</div>
				</div>
			</div>
			<div className="sale_item">
				<div className="sale_item__img">
					<img alt="Скидка 10% на грунт-эмаль" className="sale-img" src={img_emal} title="Скидка 10% на грунт-эмаль Ярославский колорит" />
				</div>
				<div className="sale_item__text">
					<p className="sale-capt"><span>Скидка</span> <b>до 30%</b> на грунт-эмаль</p>
					<div className="sale-desc">
						<p>Ярославский колорит</p>
					</div>
				</div>
			</div>
			<div className="sale_item">
				<div className="sale_item__img">
					<img alt="Скидка 15%" className="sale-img" src={img_provoda} title="Скидка 15% на остатки проводов" />
				</div>
				<div className="sale_item__text">
					<p className="sale-capt"><span>Скидка</span> <b>15%</b></p>
					<div className="sale-desc">
						<p>на остатки проводов</p>
					</div>
				</div>
			</div>
			<div className="sale_item">
				<div className="sale_item__img">
					<img alt="Скидка до 20% на люстры" className="sale-img" src={img_lustry} title="Скидка до 20% на люстры" />
				</div>
				<div className="sale_item__text">
					<p className="sale-capt"><span>Скидка до</span> <b>20%</b> на люстры</p>
				</div>
			</div>
			<div className="sale_item">
				<div className="sale_item__img">
					<img alt="Скидка 10% на ПВХ-панели" className="sale-img" src={img_pvhpaneli} title="Скидка 10% на ПВХ-панели (отдельные позиции)" />
				</div>
				<div className="sale_item__text">
					<p className="sale-capt"><span>Скидка</span> <b>10%</b> на ПВХ-панели</p>
					<div className="sale-desc">
						<p>(отдельные позиции)</p>
					</div>
				</div>
			</div>
		</div>
	)

};
export default PromoPage;
