import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { useAddToCartMutation } from "services/api/api";
import {useGetCartInfoQueryInitial} from "app/hooks";
import ModalPopup from "shared/ModalPopup";
import OrderModal from "features/Modals/OrderModal";
import ym from 'react-yandex-metrika';
import { Product } from "services/api/types/Product";
import FormatPrice from "lib/FormatPrice";
import NoImage from "shared/NoImage";

const CatalogListItem = ({product}: {product: Product}) => {
	const cartId = localStorage.getItem('cartId');
	const {data: cartInfo, isLoading: cartInfoIsLoading} = useGetCartInfoQueryInitial(cartId);
	const [addToCart] = useAddToCartMutation();
	const [isAdded, setIsAdded] = useState<boolean>(false);
	const [showModal, setShowModal] = useState<boolean>(false);
	const [modalOptions, setModalOptions] = useState<{title?: string, text?: string}>({});
	const navigate = useNavigate();
	const location = useLocation();

	const handleAddProduct = (id: number, quantity: number) => {
		if (isAdded) {
			navigate(`/cart`);
		} else {
			ym('reachGoal','catalog_order');
			addToCart({body: {product_id: id, quantity}, id: cartId});
		}
	};

	useEffect(() => {
		cartInfoIsLoading === false &&
		cartInfo.items.forEach(el => {
			if (el.product.id === product.id) {
				setIsAdded(true);
			}
		});
	}, [cartInfo, cartInfoIsLoading, product.id]);

	const openModal = (options) => {
		setShowModal(true);
		setModalOptions(options);
	};
	// обои; садовый инструмент; ковры, дорожки;  подушки, одеяла; постельное белье
	const saleCategory = [206, 220, 213, 215, 216, 218];
	const isSale = saleCategory.includes(product.category.id) ? true : false;

	return (
		<div className="col-xxs-12 col-xs-6 col-sm-4 pd-10" key={product.id}>
			<div className="catalog-item good-item" itemScope itemProp="itemListElement" itemType="http://schema.org/Product">
				<NavLink to={`${location.pathname.includes('search') ? `/catalog/${product.category.slug}` : location.pathname}/product/${product.slug}`} className="catalog-item-img center-text mr-bt-20" itemProp="url" >
					{product.images.length 
						? 	<img src={product.images[0].thumbnail} className="mw-100" alt={`${product.name}} - фото`} title={`${product.name}} - магазин Универсал в Орле`} itemProp="image" />
						: 	<NoImage/>
					}
				</NavLink>
				<NavLink to={`${location.pathname.includes('search') ? `/catalog/${product.category.slug}` : location.pathname}/product/${product.slug}`} className="catalog-item-capt">
					<span itemProp="name">{product.name}</span>
				</NavLink>
				{product.sku && <div className="good-article">Код: <span className="good-article-value">{product.sku}</span></div>}
				{product.price 
					?	<div className="good-item_price" itemScope itemProp="offers" itemType="http://schema.org/Offer">
							<span className="price-val">
								<span className="price-currency">
									Цена
									{product.is_price_starts_from && ' от'}
									:
								</span>
								<b itemProp="price"> {FormatPrice(product.price)}</b>
								<meta itemProp="priceCurrency" content="RUB" />
								<small>₽</small>{product.units && <>/{product.units}</>}
							</span>
							<div className="good-item_btns">
								<button
									className="btn btn--one category-btn"
									onClick={() => openModal({title: 'Уточнить информацию о товаре', text: 'Я хочу уточнить цену и наличие'})} >
									<span>Уточнить в 1 клик</span>
								</button>
								<button 
									className={`btn category-btn ${isAdded? 'inCart': ''}`} 
									onClick={() => handleAddProduct(product.id, 1)} >
									<span className="glyphicon glyphicon-shopping-cart"></span>
									<span className="text">{isAdded ? 'Перейти в корзину' : 'Добавить в корзину'}</span>
								</button>
							</div>
						</div>
					:	<div className="good-item_noprice">
							<button
								className="btn category-btn"
								onClick={() => openModal({title: 'Оставить заявку'})} >
								<span className="glyphicon glyphicon-shopping-cart"></span>
							</button>
							<button
								className="btn btn--one oneclick_catalog"
								onClick={() => openModal({title: 'Уточнить цену на товар:', text: 'Я хочу уточнить цену и наличие'})}>
								<span>Уточнить в 1 клик</span>
							</button>
							<a className="good-item__no-price-link" href="tel:84862510038">Цена по тел (4862) 51-00-38</a>
						</div>
				}
				<div className="catalog-item-more">
					<NavLink to={`${location.pathname.includes('search') ? `/catalog/${product.category.slug}` : location.pathname}/product/${product.slug}`}>подробнее</NavLink>
				</div>
				<meta itemProp="description" content={`${product.name} в Орле по низкой цене. Широкий ассортимент, высокое качество товаров для ремонта и обустройства дома.`}/>
				{isSale && <div className="item-sale">
					<b className="item-sale_num">-5%</b><span>по промокоду</span><b>УНИВЕРСАЛ 57</b>
				</div>}
			</div>
			{showModal && <ModalPopup setShow={setShowModal}><OrderModal product={product} options={modalOptions} setShow={setShowModal}/></ModalPopup>}
		</div>
	)
};
export default CatalogListItem;